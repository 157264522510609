import s from 'styled-components'
import Carousel from "../carousel";
import WallpaperMain from '../../images/wallpaper.jpg'
import React from 'react'

const H1 = s.h1`color:#000;margin: 0 0 5px 0;font-size: 21px;`
const Input = s.input`
    background-color: #717171;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    padding: 10px;
    width: 100%;
`
const Button = s.button`
    cursor: pointer;
    backface-visibility: hidden;
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    background: linear-gradient(180deg,#ef783d 0%,#ce642f 47.08838632142467%,#ce7042 51%,#d25e24 98.48137222768491%);
    border-radius: 5px;
    border: 1px solid #cf7028;
    border-width: 1px 1px 1px 1px;
    padding: 10px 20px 10px 20px;
    box-shadow: inset 0px 1px 0px #ffffff,0px 1px 3px rgba(0,0,0,0.3);
    color: #1b1b1b;
    font-size: 16px;
    font-family: Helvetica Neue;
    font-weight: 900;
    font-style: normal;
    text-shadow: 0px -1px 0px rgba(0,0,0,0.4)
`
// background-color: #ED2024;
// color: #fff;
// border: none;
// border-radius: 6px;
// padding: 10px;
// min-width: 100px;

const Box = s.div`
    padding: 10px 30px;
    text-align:center;
`

const Warpper = s.div`
    margin: 0 auto;
    max-width: 480px;
    position: relative;
    height: 100vh;
`

const ImgMain = s.img`
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    z-index: -100;
    top: 0;
`
const Image = s.img`
    width: 100%;
    height: 100%;
    min-height: 170px;
    background-color: #E6E6E6;
`;

const BoxMain = s.div`
  position: absolute;
  top:0;
  left:0;
`

const ViewHome = props => (
  <Warpper>
      {
            props.sliderShow.length > 0 && (
                <div>
                    <Carousel showEveryTime>
                        {
                            props.sliderShow.map((item, index) => (
                                <Image src={item} alt="" key={`slider-show-${index}`}/>
                            ))
                        }
                    </Carousel>
                </div>
            )
        }
      <Box>
        <H1>กรอกหมายเลขโทรศัพท์</H1>
        <H1>เพื่อรับสิทธิพิเศษ</H1>
      </Box>
      <Box>
        <Input type="tel" ref={props.refInput}/>
      </Box>
      <Box>
        <Button onClick={props.onSubmit}>กดยืนยัน</Button>
      </Box>
      <ImgMain src={WallpaperMain} alt=""/>
  </Warpper>
)

export default ViewHome
